import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import Card from "../Card"
import "./FarmStandSection.scss"

const FarmStandSection = ({ cardImage }) => {
  const [ref, inView] = useInView({ threshold: 0.6 })
  const controls = useAnimation()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <div className="farm-stand section" id="farm-stand">
      <motion.div
        ref={ref}
        className="farm-stand__left"
        animate={controls}
        initial="hidden"
        transition={{ duration: 1, ease: "easeOut" }}
        variants={{
          hidden: { opacity: 0.8, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <h1 className="farm-stand__title section-title">Farm Stand</h1>
        <p className="farm-stand__copy">
          Our locally grown specialty flowers can be purchased directly from our
          farm stand located at 721 South Stony Point Road, Suttons Bay, MI!
        </p>
        <p className="farm-stand__copy">
          Please check our{" "}
          <a
            className="farm-stand__social-link"
            href="https://www.facebook.com/baymeadowfarms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>{" "}
          or{" "}
          <a
            className="farm-stand__social-link"
            href="https://www.instagram.com/bay_meadow_farms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>{" "}
          for farm stand hours.
        </p>
        <p className="farm-stand__copy farm-stand__not-open">
          * Note: The farm itself is not currently open to the public.
        </p>
      </motion.div>
      <motion.div
        className="farm-stand__right"
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.8, ease: "easeOut" }}
        variants={{
          hidden: { opacity: 0.8, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <Card className="farm-stand__card">
          <GatsbyImage image={cardImage} className="farm-stand__card-image" />
        </Card>
      </motion.div>
    </div>
  )
}

export default FarmStandSection
