import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

import "./HeroSection.scss"
import { motion } from "framer-motion"

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "bmf-color-logo-3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE, layout: FIXED)
        }
      }
      heroImage: file(relativePath: { eq: "IMG_1558.JPG" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `)

  const hero = getImage(data.heroImage)
  const bgImage = convertToBgImage(hero)

  return (
    <div className="hero-section">
      <BackgroundImage
        Tag="section"
        className="hero-section__background-image"
        // fluid={heroImage}
        backgroundColor={`#040e18`}
        {...bgImage}
      >
        {/* <div className="hero-section__overlay"></div> */}
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 40 }}
          transition={{ duration: 1.2, ease: "easeOut" }}
        >
          <GatsbyImage
            image={data.logo.childImageSharp.gatsbyImageData}
            className="hero-section__logo"
            alt="Bay Meadow Farms garden"
            loading="eager"
          />
        </motion.div>
      </BackgroundImage>
    </div>
  )
}

export default HeroSection
