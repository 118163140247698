import React from "react"
import { graphql } from "gatsby"

// Shopify
import { useCart } from "gatsby-theme-shopify-manager"

// Components
import Layout from "../components/Layout/Layout"
import HeroSection from "../components/HeroSection"
import IntroSection from "../components/IntroSection"
import PeopleSection from "../components/PeopleSection"
import SEO from "../components/SEO/SEO"
import FarmStandSection from "../components/FarmStandSection"
import PhotosSection from "../components/PhotosSection"
import ContactSection from "../components/ContactSection"
import FarmersMarketSection from "../components/FarmersMarketSection/FarmersMarketSection"

// CSS
import "./index.scss"

export const query = graphql`
  {
    introImage: file(relativePath: { eq: "IMG_0475_web_res.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    introPhoto: file(relativePath: { eq: "IMG_3214.JPG" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    farmStandPhoto: file(relativePath: { eq: "farmStand1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    marketPhoto: file(relativePath: { eq: "IMG_3255.JPG" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const IndexPage = ({ data }) => {
  // const displayArticles = data.allStrapiArticle.edges.map(article => {
  //   return <p key={article.node.Title}>{article.node.Title}</p>
  // })

  const cart = useCart()

  // const displayPhotos = data.instaPhotos.edges
  //   .slice(0, 8)
  //   .map(photo => (
  //     <img
  //       className="instagram__image"
  //       key={photo.node.id}
  //       src={photo.node.preview}
  //       alt={photo.node.preview}
  //     />
  //   ))

  return (
    <Layout>
      <SEO title="Home" />
      <div className="home__page page">
        <HeroSection />
        <IntroSection
          cardImage={data.introPhoto.childImageSharp.gatsbyImageData}
        />
        <FarmersMarketSection
          cardImage={data.marketPhoto.childImageSharp.gatsbyImageData}
        />
        <FarmStandSection
          cardImage={data.farmStandPhoto.childImageSharp.gatsbyImageData}
        />
        <PeopleSection />
        <PhotosSection data={data} />
        <ContactSection />
      </div>
    </Layout>
  )
}

export default IndexPage
