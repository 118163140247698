import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FaInstagram } from "react-icons/fa"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import "./PhotosSection.scss"

const PhotosSection = () => {
  const [ref, inView] = useInView({ threshold: 0.3 })
  const controls = useAnimation()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  const displayPhotos = () => {
    return (
      <>
        <div className="flex space-between mt-32 mb-24" ref={ref}>
          <motion.div
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.8, ease: "easeOut" }}
            variants={{
              hidden: { opacity: 0.8, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <StaticImage
              className="mr-24"
              src="../../images/collage_2.png"
              alt="farm collage"
              placeholder="blurred"
              layout="constrained"
            />
          </motion.div>
          <motion.div
            animate={controls}
            initial="hidden"
            transition={{ duration: 1, ease: "easeOut" }}
            variants={{
              hidden: { opacity: 0.8, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <StaticImage
              src="../../images/collage_1.png"
              alt="farm collage"
              placeholder="blurred"
              layout="constrained"
            />
          </motion.div>
        </div>
        <StaticImage
          className="mb-24"
          src="../../images/collage_3.png"
          alt="farm collage"
          placeholder="blurred"
          layout="constrained"
        />
        <StaticImage
          className="mb-24"
          src="../../images/collage_4.png"
          alt="farm collage"
          placeholder="blurred"
          layout="constrained"
        />
      </>
    )
  }

  return (
    <section className="section photos-section" id="photos">
      <div className="section__inner">
        <h2 className="section-title">Follow Us on Instagram</h2>
        <a
          href="https://www.instagram.com/bay_meadow_farms/"
          className="photos-section__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            <FaInstagram className="photos-section__icon" />
          </span>
          <span className="photos-section__link-title">bay_meadow_farms</span>
        </a>
        <div className="photos-section__photos">{displayPhotos()}</div>
      </div>
    </section>
  )
}

export default PhotosSection
