import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import "./EmailListForm.scss"

const EmailListForm = () => {
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [message, setMessage] = useState("Subscribe to our email list!")

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const data = await addToMailchimp(email, {
        PATHNAME: "/",
        FNAME: firstName,
      })

      if (data && data.result === "success") {
        setMessage("Thanks for subscribing!")
        setFirstName("")
        setEmail("")
      } else if (
        data &&
        data.result === "error" &&
        data.msg.includes("already subscribed")
      ) {
        setMessage("You're already subscribed!")
        setFirstName("")
        setEmail("")
      } else {
        setMessage("Hmm, is that a valid email?")
        setTimeout(() => setMessage("Subscribe to our email list!"), 5000)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const handleFirstNameChange = e => {
    setFirstName(e.currentTarget.value)
  }

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value)
  }

  return (
    <form onSubmit={handleSubmit} className="email-list-form">
      <h2>{message}</h2>
      <input
        className="email-list-form__firstName"
        placeholder="First Name"
        name="firstName"
        type="text"
        value={firstName}
        onChange={handleFirstNameChange}
      />
      <div className="email-list-form__wrapper">
        <input
          className="email-list-form__email"
          placeholder="Email address"
          name="email"
          type="text"
          value={email}
          onChange={handleEmailChange}
        />
        <button type="submit">Subscribe</button>
      </div>
    </form>
  )
}

export default EmailListForm
