import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import Card from "../Card"
import EmailListForm from "../EmailListForm/EmailListForm"

import "./IntroSection.scss"

const IntroSection = ({ cardImage }) => {
  const [ref, inView] = useInView({ threshold: 0.5 })
  const controls = useAnimation()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <div className="intro-section section" id="newsletter">
      <motion.div
        ref={ref}
        className="intro-section__left"
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.6, ease: "easeOut" }}
        variants={{
          hidden: { opacity: 0.8, x: -20 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <h2 className="intro-section__title section-title">
          <span>Specialty</span>
          <span>Flowers</span>
        </h2>
        <div className="intro-section__paragraph paragraph-text">
          Welcome! Bay Meadow Farms is a family-run fresh cut specialty flower
          farm in Suttons Bay, Michigan. We grow our unique flowers on the same
          land our family has gardened on for over 100 years. We practice
          regenerative farming and have a passion for sharing our love of
          flowers with the community in Northern Michigan.
          <span role="img" aria-label="flower">
            🌸
          </span>
          <span role="img" aria-label="cherries">
            🍒
          </span>
        </div>
        <div>
          <EmailListForm />
        </div>
      </motion.div>
      <motion.div
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.5, ease: "easeOut" }}
        variants={{
          hidden: { opacity: 0.8, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <Card className="intro-section__card">
          <GatsbyImage
            image={cardImage}
            className="intro-section__card-image"
          />
        </Card>
      </motion.div>
    </div>
  )
}

export default IntroSection
