import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Card from "../Card"

import "./PeopleSection.scss"

const PeopleSection = () => {
  const data = useStaticQuery(graphql`{
  bayImage: file(relativePath: {eq: "IMG_0402-crop-web-res.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, height: 450, layout: FIXED)
    }
  }
  winnieImage: file(relativePath: {eq: "IMG_0463-crop-web-res.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, height: 450, layout: FIXED)
    }
  }
  rachelImage: file(relativePath: {eq: "bmf-rachel.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, height: 450, layout: FIXED)
    }
  }
  margoImage: file(relativePath: {eq: "margo.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, height: 450, layout: FIXED)
    }
  }
}
`)

  const people = [
    { name: "Bay", image: data.bayImage },
    { name: "Margo", image: data.margoImage },
  ]

  const displayPeople = people.map(person => {
    return (
      <Fragment key={person.name}>
        <Card className="people-section__name-card">
          <div className="people-section__image-wrapper">
            <GatsbyImage
              image={person.image.childImageSharp.gatsbyImageData}
              className="people-section__image" />
          </div>
          <p className="people-section__name">{person.name}</p>
        </Card>
      </Fragment>
    );
  })

  return (
    <div className="people-section section">
      <h2 className="people-section__title section-title">Our Team</h2>
      <div className="people-section__team-members">{displayPeople}</div>
    </div>
  )
}

export default PeopleSection
