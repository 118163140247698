import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import Card from "../Card"
import "./FarmersMarketSection.scss"

const FarmersMarketSection = ({ cardImage }) => {
  const [ref, inView] = useInView({ threshold: 0.6 })
  const controls = useAnimation()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <div className="farmers-market section" id="farmers-market" ref={ref}>
      <motion.div
        className="farmers-market__left"
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.6, ease: "easeOut" }}
        variants={{
          hidden: { opacity: 0.8, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <Card className="farmers-market__card">
          <GatsbyImage
            image={cardImage}
            className="farmers-market__card-image"
          />
        </Card>
      </motion.div>
      <motion.div
        className="farmers-market__right"
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.6, ease: "easeOut" }}
        variants={{
          hidden: { opacity: 0.8, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <h2 className="farmers-market__title section-title">Farmers Market</h2>
        <p className="farmers-market__copy">
          We can’t wait to see you for the 2023 season at the Glen Arbor and
          Northport farmers markets!
        </p>
        <Card className="farmers-market__info-card">
          <h3>Glen Arbor Farmers Market</h3>
          <p>Time: Tuesdays, 9am - 1pm</p>
          <p>
            Website:{" "}
            <a
              href="https://leelanaufarmersmarkets.com/glen-arbor/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Glen Arbor Farmers Market Website
            </a>
          </p>
        </Card>
        <Card className="farmers-market__info-card">
          <h3>Northport Farmers Market</h3>
          <p>Time: Fridays, 9am - 1pm</p>
          <p>
            Website:{" "}
            <a
              href="https://leelanaufarmersmarkets.com/northport/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Northport Farmers Market Website
            </a>
          </p>
        </Card>
        <p className="farmers-market__copy">
          Stop by to purchase a locally grown bouquet of specialty flowers!
        </p>
      </motion.div>
    </div>
  )
}

export default FarmersMarketSection
